import { createContext, useState, useContext, useEffect } from 'react'
import { navigateToCart } from './ManageBasket'
import eCommerceActions from 'ecommerce-module/core/eCommerce.actions'

const BasketContext = createContext({})

export const useBasket = () => useContext(BasketContext)

export const BasketProvider = ({ values, children }) => {
  const [basket, setBasket] = useState(null)

  useEffect(() => {
    const storedBasketId = localStorage.getItem('basketId')
    if (storedBasketId && storedBasketId !== 'undefined') {
      validateAndSetBasket(storedBasketId)
    } else {
      createNewBasket()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateAndSetBasket = async (basketId) => {
    try {
      const basketDetails = await fetchBasket(basketId)
      if (basketDetails && basketDetails.basketId) {
        setBasket(basketId)
      } else {
        await createNewBasket()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const createNewBasket = async () => {
    const newBasket = await eCommerceActions.createBaskets()
    if (newBasket?.basketId) {
      setBasket(newBasket.basketId)
      localStorage.setItem('basketId', newBasket.basketId)
      return newBasket
    }
    return null
  }

  const addProductToBasket = async (product) => {
    const storedBasketId = localStorage.getItem('basketId')
    if (!storedBasketId || storedBasketId === 'undefined') {
      await createNewBasket()
    }

    await eCommerceActions.addItemsToBasket(basket, product)
  }

  const fetchBasket = async (basketId) => {
    return await eCommerceActions.getBasket(basketId)
  }

  const removeItem = async (basketId, itemId) => {
    return await eCommerceActions.deleteItemFromBasket(basketId, itemId)
  }

  const removeBasket = async (basketId) => {
    return await eCommerceActions.deleteBasket(basketId)
  }

  return (
    <BasketContext.Provider value={{ basket, addProductToBasket, fetchBasket, createNewBasket, removeBasket, removeItem, navigateToCart, ...values }}>
      { children }
    </BasketContext.Provider>
  )
}
